.proposal-table thead,
.proposal-table td {
    font-size: 14px;

}

.proposal-table td {
    font-size: 12px;
}

.custom-table-container {
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.custom-table-container table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  margin-bottom: 0;
}

.custom-table-container thead th {
  background: #f8f9fa;
  color: #344767;
  font-weight: 600;
  padding: 12px 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #eee;
  white-space: nowrap;
}

.custom-table-container tbody tr {
  transition: all 0.2s ease;
}

.custom-table-container tbody tr:hover {
  background-color: #f8f9fa;
}

.custom-table-container td {
  padding: 12px 16px;
  vertical-align: middle;
  border-bottom: 1px solid #eee;
  color: #525f7f;
}

.custom-table-container tbody tr:last-child td {
  border-bottom: none;
}

/* Status colors */
.custom-table-container tr[style*="orange"] {
  border-left: 4px solid #ff9800 !important;
}

.custom-table-container tr[style*="green"] {
  border-left: 4px solid #4caf50 !important;
}

/* Responsive design */
@media (max-width: 768px) {
  .custom-table-container {
    margin: 0.5rem 0;
    border-radius: 6px;
  }
  
  .custom-table-container td,
  .custom-table-container th {
    padding: 10px 12px;
  }
}